.side-bar-container {
    width: 160px;
    height: calc(100vh - 60px);
    border-right: 1px solid rgb(231, 235, 240);
    position: relative;
    overflow-y: auto;
}

.side-bar-container span {
    font-size: 12px !important;
}

.side-bar-menu {
    border-bottom: 1px solid rgb(231, 235, 240) !important;
}

@media screen and (max-width:600px) {
    .side-bar-container {
        width: 70px;
        height: calc(100vh - 100px) !important;
    }

    .side-bar-text,
    .side-bar-expand-more,
    .side-bar-expand-less {
        display: none !important;
    }

    .hidden-side-bar-menu {
        padding: 8px 16px 8px 16px !important;
    }
}
