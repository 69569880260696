/* .rbc-button-link {
    pointer-events: none !important;
} */

.rbc-btn-group .rbc-active, .rbc-btn-group .rbc-active ~ * {
    display: none;
}

.calendar-date-filter {
    margin-bottom: 20px;
}