.receipt-container {
    size: 21cm 29.7cm;
    margin: 10mm 10mm 10mm 10mm;
}

.receipt-content {
    max-width: 21cm;
    margin: auto;
    height: 100%;
    text-align: center;
}