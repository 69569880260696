.view {
    padding:20px;
    width: calc(100vw - 160px);
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width:600px) {
    .view {
        width: 100%
    }
}