.single-payment-info-container {
    padding: 20px;
    border: 1px solid rgb(231, 235, 240);
    position: relative;
}

.single-payment-info:not(.single-payment-info:last-child) {
    margin-bottom: 10px;
}

.single-payment-info-edit-button {
    position: absolute;
    right: 20px;
}

@media screen and (max-width:600px) {
    .single-payment-info-edit-button {
        position: relative;
        right: unset;
    }

    .single-payment-info-edit-button .info-button{
        margin-bottom: 10px;
        width: 100%;
    }
}