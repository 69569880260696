.client-room-payments-search-container {
    width: 100%;
    margin-bottom: 20px;
}

.client-room-payments-table {
    margin-top: 20px;
}

.client-room-payments-pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-room-payments-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-room-payments-modal-form {
    max-width: 500px;
    width: 100%;
    background-color: white !important;
    border-radius: 10px;
    position: relative;
}

.client-room-payments-modal-form-controls {
    padding: 20px;
}

.client-room-payments-modal-close-button {
    position: absolute;
}

.payment-change-container {
    border: 1px solid rgb(231, 235, 240);
    padding: 20px;
    margin-bottom: 10px;
}

@media screen and (max-height:700px) {
    .client-room-payments-modal {
        display: block;
    }

    .client-room-payments-modal-form {
        margin: auto;
        overflow-y: auto;
        border-radius: 0px;
        height: 100%;
    }
}

@media screen and (max-width:600px) {
    .client-room-payments-pagination-container {
        display: flex;
       flex-direction: column;
        text-align: center;
    }

    .client-room-payments-modal {
        display: block;
    }

    .client-room-payments-modal-form {
        max-width: 100%;
        overflow-y: auto;
        border-radius: 0px;
        height: 100%;
    }
}