.header {
    width: 100%;
    border-bottom: 1px solid rgb(231, 235, 240);
    height: 60px;
}

.header-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: right;
    margin-right: 10px;
}

.header-account-name {
    margin-right: 5px;
}

.header-account-menu-icon {
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.54);
}

@media screen and (max-width: 600px){
    .header-account-name {
        padding-left: 20px;
    }

    .header {
        height: 100px;
    }
}