::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background: #f1f2f3;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width:600px) {
    ::-webkit-scrollbar {
        width: 5px !important;
    }
}