.index-logo {
    display: block;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 30px;
}

.index-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.index-form-container {
    max-width: 400px;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
}

@media screen and (max-width: 350px) and (max-height: 600px) {
    .index-container {
        align-items: flex-start;
    }
}