.clients-search-container {
    width: 100%;
    margin-bottom: 20px;
}

.clients-table {
    margin-top: 20px;
}

.clients-pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.clients-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.clients-modal-form {
    max-width: 500px;
    width: 100%;
    background-color: white !important;
    border-radius: 10px;
    position: relative;
}

.clients-modal-form-controls {
    padding: 20px;
}

.clients-modal-close-button {
    position: absolute;
}

@media screen and (max-height:700px) {
    .clients-modal {
        display: block;
    }

    .clients-modal-form {
        margin: auto;
        overflow-y: auto;
        border-radius: 0px;
        height: 100%;
    }
}

@media screen and (max-height:700px) {
    .clients-modal {
        display: block;
    }

    .clients-modal-form {
        margin: auto;
        overflow-y: auto;
        border-radius: 0px;
        height: 100%;
    }
}

@media screen and (max-width:600px) {
    .clients-pagination-container {
        display: flex;
       flex-direction: column;
        text-align: center;
    }

    .clients-modal {
        display: block;
    }

    .clients-modal-form {
        max-width: 100%;
        overflow-y: auto;
        border-radius: 0px;
        height: 100%;
    }
}

